<template>
  <div>
    <v-btn
      v-if="$store.getters['auth/isLoggedIn']"
      color="primary"
      @click.native="$router.back()"
      style="
        position: absolute;
        height: 30px;
        left: 15px;
        top: 30px;
        z-index: 5;
      "
      outlined
    >
      <v-icon>mdi-arrow-left</v-icon>
      {{ $t("back") }}
    </v-btn>
    <v-img
      class="white--text align-end"
      src="@/assets/background.jpg"
      style="height: 300px"
    >
      <v-row justify="center" align="center" style="height: 300px">
        <v-col cols="8" md="2">
          <img width="100%" src="@/assets/logo.png" />
        </v-col>
      </v-row>
    </v-img>
    <slot></slot>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "TattooerCard",
  computed: {
    ...mapState("inscriptions", ["studioState", "tattooerState"]),
    tattooer: {
      get() {
        return this.tattooerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_TATTOOER", value);
      },
    },
  },
};
</script>
<style lang="sass">
.v-responsive__sizer
  display: none !important
</style>